<template lang="pug">
  .main-view
    vue-scroll.scroll-wrapper-setup-page(ref='vs' id='scroll-panel')
      .row.justify-content-center
        .col-xl-12.mt-5
          .mr-5.ml-5
            .portlet
              .portlet__body.portlet__body--fit
                setup-form
</template>

<script>
import SetupForm from './common/SetupForm'
import { mapGetters } from 'vuex'

export default {
  name: 'Setup',

  components: {
    SetupForm
  },

  computed: {
    ...mapGetters('setupWizard', ['srollToPosY'])
  },

  watch: {
    srollToPosY: {
      handler (param) {
        this.$refs['vs'].scrollBy(
          {
            dy: param
          },
          600,
          'easeInQuad'
        )
      }
    }
  }
}
</script>

<style lang="scss">
  .kt-form__actions {
    button {
      i {
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }

  .kt-wizard__separator {
      margin-top: 4rem;
      margin-bottom: 4rem;
      border: 0;
      border-bottom: 1px solid #eeeef4;
  }

  .main-view {
    height: 100vh;
  }

  .scroll-wrapper-setup-page{
     height: 100vh;
  }
</style>
